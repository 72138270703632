var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rule-templates-view"},[_c('Header',{attrs:{"title":_vm.$t('title')}}),_c('div',{staticClass:"l-stack l-gap-4 rule-templates-view__container"},[_c('router-link',{attrs:{"to":{ name: 'ruleCreate' }}},[_c('notification-settings-item',{attrs:{"title":_vm.$t('noTemplate'),"description":_vm.$t('noTemplateHint')},scopedSlots:_vm._u([{key:"iconRight",fn:function(){return [_c('arrow-right-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true}])})],1),_c('div',{staticClass:"l-stack l-gap-2"},[_c('h2',[_vm._v(_vm._s(_vm.$t('subTitle')))]),_c('base-input',{attrs:{"debounce":400,"placeholder":_vm.$t('searchPlaceholder')},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('search-icon',{attrs:{"width":"16","height":"16"}})]},proxy:true}]),model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('transition-group',{staticClass:"templates-list",attrs:{"name":"templates-list","tag":"div"}},_vm._l((_vm.templatesFiltered),function(template){return _c('router-link',{key:template.id,attrs:{"to":{
                    name: 'ruleCreate',
                    params: {
                        initialAdmin: template.admin,
                        initialName: template.name,
                        initialConditions: template.conditions.conditions,
                    },
                }}},[_c('notification-settings-item',{attrs:{"title":template.name,"description":template.description},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('monitor-heart-rate-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true}],null,true)})],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }