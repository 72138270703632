<template>
    <div
        class="l-inline l-gap-2 l-center-v notification-settings-item"
        :class="{
            'notification-settings-item-empty': empty,
        }"
        v-on="$listeners"
    >
        <div v-if="$slots.icon" class="notification-settings-item-icon">
            <slot name="icon"></slot>
        </div>

        <div class="notification-settings-item-text">
            <strong>{{ title }}</strong>

            {{ description }}
        </div>

        <div v-if="$slots.iconRight" class="notification-settings-item-icon">
            <slot name="iconRight"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotificationSettingsItem',
    props: {
        description: {
            type: String,
            required: true,
        },
        empty: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            required: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.notification-settings-item {
    padding: 8px;
    background-color: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    border: 1px solid #fff;
    border-radius: 8px;
    font-size: 14px;
    color: $color-text-new;

    &-empty {
        box-sizing: border-box;
        background-color: transparent;
        border: 1px dashed #000;
    }

    &-text {
        width: 100%;
        margin: 0 16px;

        & + div {
            background-color: transparent;
        }
    }

    &-icon {
        padding: 8px;
        width: 40px;
        height: 40px;
        background-color: $color-light;
        border-radius: 50%;
    }

    strong {
        display: block;
        margin-bottom: 2px;
        font-size: 16px;
    }
}
</style>
