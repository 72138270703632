<template>
    <div class="rule-templates-view">
        <Header :title="$t('title')" />

        <div class="l-stack l-gap-4 rule-templates-view__container">
            <router-link :to="{ name: 'ruleCreate' }">
                <notification-settings-item
                    :title="$t('noTemplate')"
                    :description="$t('noTemplateHint')"
                >
                    <template #iconRight>
                        <arrow-right-icon width="24" height="24" />
                    </template>
                </notification-settings-item>
            </router-link>

            <div class="l-stack l-gap-2">
                <h2>{{ $t('subTitle') }}</h2>

                <base-input
                    v-model="filter"
                    :debounce="400"
                    :placeholder="$t('searchPlaceholder')"
                >
                    <template #icon>
                        <search-icon width="16" height="16" />
                    </template>
                </base-input>
            </div>

            <transition-group
                name="templates-list"
                tag="div"
                class="templates-list"
            >
                <router-link
                    v-for="template in templatesFiltered"
                    :key="template.id"
                    :to="{
                        name: 'ruleCreate',
                        params: {
                            initialAdmin: template.admin,
                            initialName: template.name,
                            initialConditions: template.conditions.conditions,
                        },
                    }"
                >
                    <notification-settings-item
                        :title="template.name"
                        :description="template.description"
                    >
                        <template #icon>
                            <monitor-heart-rate-icon width="24" height="24" />
                        </template>
                    </notification-settings-item>
                </router-link>
            </transition-group>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { httpHelper, measurementHelper } from '@/utils'

import ArrowRightIcon from '../../icons/ArrowRightIcon'
import BaseInput from '../../redesigned/BaseInput'
import Header from '../../redesigned/Header'
import MonitorHeartRateIcon from '../../icons/MonitorHeartRateIcon'
import NotificationSettingsItem from '../../redesigned/NotificationSettingsItem'
import SearchIcon from '../../icons/SearchIcon'

export default {
    name: 'RuleTemplatesView',
    components: {
        ArrowRightIcon,
        BaseInput,
        Header,
        MonitorHeartRateIcon,
        NotificationSettingsItem,
        SearchIcon,
    },
    data() {
        return {
            filter: '',
            templates: [],
        }
    },
    computed: {
        ...mapGetters('locations', ['getLocationsSortedByName']),
        templatesFiltered() {
            let templates = this.templates

            if (this.filter) {
                const filter = this.filter.toLowerCase()
                templates = templates.filter(
                    item =>
                        item.name.toLowerCase().includes(filter) ||
                        item.description.toLowerCase().includes(filter)
                )
            }

            return templates
        },
    },
    async mounted() {
        const { data } = await httpHelper.get('/alertrule-templates/templates/')
        this.templates = data.results
        this.templates.forEach(template => {
            template.conditions.conditions.forEach(item => {
                if (item.value === 'battery_percent') {
                    item.value = 'battery'
                }
            })

            template.description = this.getTemplateDescription(template)
        })
    },
    methods: {
        getLocationName(locationId) {
            return this.getLocationsSortedByName.find(
                location => location.id === locationId
            )?.name
        },
        getTemplateDescription(template) {
            return template.conditions.conditions
                .map(condition =>
                    condition.type === 'geofence'
                        ? `${condition.position} ${this.getLocationName(
                              condition.location
                          )}`
                        : `${this.$t(
                              'shared.measurements.' + condition.value
                          )} ${condition.operator} ${condition.threshold} ${
                              measurementHelper.units[condition.value]
                          }`
                )
                .join(
                    ` ${this.$t(template.conditions.matchMode + 'MatchMode')} `
                )
        },
    },
}
</script>

<i18n>
{
    "en": {
        "allMatchMode": "and",
        "anyMatchMode": "or",
        "noTemplate": "Continue without template",
        "noTemplateHint": "Configure everything by youself",
        "searchPlaceholder": "Durchsuchen",
        "subTitle": "Vorlage auswählen",
        "title": "Rule Templates"
    },
    "de": {
        "allMatchMode": "und",
        "anyMatchMode": "oder",
        "noTemplate": "Weiter ohne Vorlage",
        "noTemplateHint": "Alles selbst konfigurieren",
        "searchPlaceholder": "Durchsuchen",
        "subTitle": "Vorlage auswählen",
        "title": "Regel Vorlagen"
    },
    "it": {
        "allMatchMode": "and",
        "anyMatchMode": "or",
        "noTemplate": "Weiter ohne Vorlage",
        "noTemplateHint": "Alles selbst konfigurieren",
        "searchPlaceholder": "Durchsuchen",
        "subTitle": "Vorlage auswählen",
        "title": "Regel Vorlagen"
    }
}
</i18n>

<style lang="scss" scoped>
.rule-templates-view {
    &__container {
        margin: 48px auto;
        max-width: 340px;
    }

    h2 {
        font-weight: 700;
        font-size: 20px;
        color: $color-text-new;
    }
}

.templates-list {
    position: relative;

    & > * {
        display: block;
        width: 100%;
        transition: all 200ms;

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }

    &-leave-active {
        position: absolute;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
    }
}
</style>
