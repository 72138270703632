<template>
    <div class="l-padded l-inline l-spread l-center-v header">
        <remove-icon
            v-if="$listeners.close"
            width="24"
            height="24"
            class="clickable"
            @click="$emit('close')"
        />

        <router-link v-else to=".." append>
            <remove-icon width="24" height="24" />
        </router-link>

        <div class="l-padded-x title">
            {{ title }}
        </div>
    </div>
</template>

<script>
import RemoveIcon from '../icons/RemoveIcon'

export default {
    name: 'Header',
    components: {
        RemoveIcon,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.header {
    background-color: #fff;
    border-bottom: 1px solid $color-gray-light-new;

    & > * {
        height: 24px;
    }
}

.title {
    margin-right: 24px;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: $color-text-new;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
